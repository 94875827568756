import React, { useState, useRef, useEffect } from 'react'
import useWindowSize from '@hooks/useWindowSize'

export const useIntersectOnMobile = (
  options = {
    root: null,
    threshold: 0.5,
  },
  breakpoint = 1023
) => {
  const containerRef = useRef(null)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const isMobile = useWindowSize().width <= breakpoint

  const intersectHandler = (entries, observer) => {
    const [entry] = entries
    if (!entry.isIntersecting) return
    setIsIntersecting(true)
    observer.unobserve(entry.target)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(intersectHandler, options)
    const current = containerRef.current

    if (!isMobile) {
      return observer.unobserve(current)
    }

    if (current && isMobile) observer.observe(current)

    return () => {
      if (current) observer.unobserve(current)
    }
  }, [options, isMobile])

  return [containerRef, isIntersecting, isMobile]
}

export const useIntersect = (
  options = {
    root: null,
    threshold: 1,
  }
) => {
  const containerRef = useRef(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  const intersectHandler = (entries) => {
    const [entry] = entries
    setIsIntersecting(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(intersectHandler, options)
    const current = containerRef.current

    if (current) observer.observe(current)

    return () => {
      if (current) observer.unobserve(current)
    }
  }, [options])

  return [containerRef, isIntersecting]
}
