import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import SvgArrowAlt from '@svg/arrowAlt.inline'
import Buttons from '@components/global/buttons/Buttons'
import useWindowSize from '@hooks/useWindowSize'
import * as cx from './ReadMoreAccordion.module.scss'

const ReadMoreAccordion = ({
  wrapperClassName,
  minHeight = 18,
  paragraphs,
  paragraphsClassName = 'default-body large',
  ctaText,
  ghostText = 'Read More',
  closeText = 'Read Less',
  ctaOnClick,
}) => {
  const [isReadMore, setIsReadMore] = useState(false)
  const isNotPhone = useWindowSize().width >= 640
  const [maxHeightContent, setMaxHeightContent] = useState(`${minHeight}rem`)
  const ctaButtonRef = useRef(null)
  const ghostButtonRef = useRef(null)
  const contentRef = useRef(null)
  const maxHeight = isNotPhone ? 'none' : `${maxHeightContent}`

  const readMoreHandler = () => {
    setIsReadMore((isReadMore) => !isReadMore)
    setMaxHeightContent(
      isReadMore ? '0rem' : `${contentRef.current.scrollHeight}rem`
    )
  }

  return (
    <div
      className={clsx([cx.wrapper], wrapperClassName, {
        [cx.readMore]: isReadMore,
      })}
    >
      <div
        ref={contentRef}
        className={clsx([cx.content])}
        style={{
          maxHeight: `${maxHeight}`,
          minHeight: `${minHeight}rem`,
        }}
      >
        <p className={paragraphsClassName}>{parse(paragraphs)}</p>
      </div>
      <Buttons
        large
        wrapperClassName={cx.buttons}
        ctaRef={ctaButtonRef}
        ghostRef={ghostButtonRef}
        ctaText={ctaText}
        ghostText={isReadMore ? closeText : ghostText}
        ghostSvg={<SvgArrowAlt />}
        ctaOnClick={ctaOnClick}
        ghostOnClick={readMoreHandler}
      />
    </div>
  )
}

ReadMoreAccordion.propTypes = {
  wrapperClassName: PropTypes.string,
  minHeight: PropTypes.number,
  paragraphs: PropTypes.string,
  paragraphsClassName: PropTypes.string,
  ctaText: PropTypes.string,
  ghostText: PropTypes.string,
  closeText: PropTypes.string,
  ctaOnClick: PropTypes.func,
}

export default ReadMoreAccordion
