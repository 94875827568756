import { useStaticQuery, graphql } from 'gatsby'

const useBest20IDXMetadata = () => {
  const query = useStaticQuery(graphql`
    query best20IDXMetadata {
      bannerBG: file(
        relativePath: { eq: "best-20-idx-websites/banner/banner-bg.jpg" }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      bannerMagnify: file(
        relativePath: { eq: "best-20-idx-websites/banner/banner-magnify.png" }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      bannerTabletLandscape: file(
        relativePath: {
          eq: "best-20-idx-websites/banner/banner-tablet-landscape.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      bannerTabletSlant: file(
        relativePath: {
          eq: "best-20-idx-websites/banner/banner-tablet-slant.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }

      brandingAccent: file(
        relativePath: {
          eq: "best-20-idx-websites/branding/branding-accent.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      brandingTablet1: file(
        relativePath: {
          eq: "best-20-idx-websites/branding/branding-tablet-1.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      brandingTablet2: file(
        relativePath: {
          eq: "best-20-idx-websites/branding/branding-tablet-2.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }

      websiteListBG: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-bg.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListQuianaLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-quiana2-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListQuianaTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-quiana2-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListQuianaMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-quiana2-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCarollLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-caroll-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCarollTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-caroll-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCarollMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-caroll-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSharleneLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sharlene-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSharleneTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sharlene-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSharleneMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sharlene-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListGregLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-greg-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListGregTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-greg-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListGregMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-greg-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDrakeLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-drake-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDrakeTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-drake-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDrakeMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-drake-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDaftarianLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-daftarian-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDaftarianTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-daftarian-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDaftarianMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-daftarian-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListJillsLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-jills-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListJillsTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-jills-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListJillsMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-jills-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListHigginsLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-higgins-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListHigginsTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-higgins-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListHigginsMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-higgins-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCaseyLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-casey-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCaseyTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-casey-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCaseyMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-casey-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSherLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sher-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSherTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sher-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSherMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sher-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListTomLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-tom-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListTomTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-tom-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListTomMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-tom-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListIvanLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-ivan-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListIvanTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-ivan-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListIvanMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-ivan-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListBattleLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-battle-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListBattleTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-battle-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListBattleMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-battle-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListKaterinaLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-katerina-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListKaterinaTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-katerina-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListKaterinaMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-katerina-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDemakisLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-demakis-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDemakisTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-demakis-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListDemakisMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-demakis-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSueLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sue-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSueTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sue-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListSueMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-sue-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListArmarioLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-armario-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListArmarioTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-armario-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListArmarioMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-armario-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListBrianLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-brian-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListBrianTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-brian-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListBrianMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-brian-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCapeLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-cape-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCapeTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-cape-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListCapeMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-cape-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListJulieLaptop: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-julie-laptop.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListJulieTablet: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-julie-tablet.jpg"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }
      websiteListJulieMagnify: file(
        relativePath: {
          eq: "best-20-idx-websites/websitelist2/websitelist-julie-magnify.png"
        }
      ) {
        id
        ...Best20IDXImageOptimize
      }

      faqsBG: file(
        relativePath: { eq: "best-20-idx-websites/others/faqs-bg.jpg" }
      ) {
        id
        ...Best20IDXImageOptimize
      }
    }

    fragment Best20IDXImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useBest20IDXMetadata
