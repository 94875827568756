import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import DeviceLaptop from '../Laptop'
import DeviceTablet from '../Tablet'
import laptopPlaceholder from '/content/assets/best-20-idx-websites/websitelist2/websitelist-laptop-placeholder.png'
import tabletPlaceholder from '/content/assets/best-20-idx-websites/websitelist2/websitelist-tablet-placeholder.png'
import * as cx from './IDXDevicesV2.module.scss'

const IDXDevicesV2 = ({
  wrapperClassName,
  laptopSrc,
  tabletSrc,
  magnifyUrl,
  laptopAlt = 'IDX laptop',
  tabletAlt = 'IDX tablet',
}) => {
  return (
    <div className={clsx([cx.wrapper], wrapperClassName)}>
      <canvas width={686} height={701} className={cx.placeholder}></canvas>

      <DeviceLaptop
        className={cx.laptop}
        imagePlaceholder={laptopPlaceholder}
        imageFile={laptopSrc}
        imageFileAlt={laptopAlt}
        width={1000}
        height={540}
      />
      <DeviceTablet
        rotate
        className={cx.tablet}
        imagePortraitPlaceholder={tabletPlaceholder}
        imageFile={tabletSrc}
        imageFileAlt={tabletAlt}
      />
      <canvas
        className={cx.magnify}
        style={{ backgroundImage: `url(${magnifyUrl})` }}
        width={226}
        height={226}
      ></canvas>
    </div>
  )
}

IDXDevicesV2.propTypes = {
  wrapperClassName: PropTypes.string,
  laptopSrc: PropTypes.object,
  tabletSrc: PropTypes.object,
  magnifyUrl: PropTypes.string,
  laptopAlt: PropTypes.string,
  tabletAlt: PropTypes.string,
}

export default IDXDevicesV2
