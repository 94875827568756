import { useStaticQuery, graphql } from 'gatsby'

export const useContactFormMetadata = () => {
  const query = useStaticQuery(graphql`
    query LeadContentFormPlaceholderImage {
      wp {
        leadContactForm {
          background {
            id
            name
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }

          model {
            id
            name
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
      }

      DeviceTabletPortrait: file(
        relativePath: { eq: "form/ipad-portrait.jpg" }
      ) {
        id
        ...LeadContentFormPlaceholderBackgroundImageOptimize
      }
      DeviceTabletPortraitAlt: file(
        relativePath: { eq: "form/ipad-portrait-alt.jpg" }
      ) {
        id
        ...LeadContentFormPlaceholderBackgroundImageOptimize
      }

      # Samantha: file(
      #   relativePath: { eq: "form/model-background/Samantha.jpg" }
      # ) {
      #   id
      #   ...LeadContentFormPlaceholderModelImageOptimize
      # }
    }

    fragment LeadContentFormPlaceholderBackgroundImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }

    # fragment LeadContentFormPlaceholderModelImageOptimize on File {
    #   childImageSharp {
    #     gatsbyImageData(
    #       placeholder: NONE
    #       quality: 90
    #       formats: [AUTO]
    #       width: 660
    #       height: 880
    #     )
    #   }
    # }

    # fragment LeadContentFormPlaceholderCutOutModelImageOptimize on File {
    #   childImageSharp {
    #     gatsbyImageData(
    #       placeholder: NONE
    #       quality: 90
    #       formats: [AUTO]
    #       width: 534
    #       height: 794
    #     )
    #   }
    # }
  `)

  return query
}
