import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { isSafari } from 'react-device-detect'
import clsx from 'clsx'

import useMedia, { media } from '@hooks/useMedia'
import { useIntersectOnMobile } from '@hooks/useIntersect'
import IDXDevicesV2 from '@components/global/devices/IDXDevicesV2'
import ReadMoreAccordion from '@components/global/accordions/ReadMoreAccordion'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import * as cx from './WebsiteList.module.scss'

const WebsiteItem = ({
  agent,
  location,
  description,
  buttonText,
  bgImg,
  laptopImg,
  tabletImg,
  magnifyImg,
  agentClassName = 'subtitle-2',
  locationClassName = 'subtitle-7',
  ctaOnClick,
}) => {
  const [cardRef, isCardIntersecting, isMobile] = useIntersectOnMobile()

  return (
    <li
      className={cx.itemWrapper}
      style={{
        backgroundImage: `url(${bgImg})`,
      }}
    >
      <div className={cx.item}>
        <IDXDevicesV2
          wrapperClassName={cx.devices}
          laptopSrc={laptopImg}
          tabletSrc={tabletImg}
          magnifyUrl={magnifyImg}
        />
        <div
          ref={cardRef}
          className={clsx([cx.content], {
            'tempo-hidden': isMobile && !isSafari && !isCardIntersecting,
            [cx.cardIntersect]: isMobile && !isSafari && isCardIntersecting,
          })}
        >
          <div className={cx.contentInner}>
            <h4>
              <span className={agentClassName}>{agent}</span>
              <span className={locationClassName}> {location}</span>
            </h4>
            <hr />
            <ReadMoreAccordion
              ctaText={buttonText}
              paragraphs={description}
              ctaOnClick={ctaOnClick}
            />
          </div>
        </div>
      </div>
    </li>
  )
}

const WebsiteList = ({
  wrapperClassName,
  bgImg,
  heading,
  subheading,
  buttonText,
  headingClassName = 'heading-2',
  headingTabletClassName = 'heading-3',
  subheadingClassName = 'subtitle-1',
  subheadingTabletClassName = 'subtitle-7',
  item: websiteItem,
}) => {
  const isTablet = useMedia(media.tablet)
  const modalRef = useRef()

  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <ModalForm
        childFunc={modalRef}
        desktopTitle="Sign Up Today For A <br>Free Consultation"
        mobileTitle="Sign Up Today For A <br>Free Consultation"
        template="m1"
        type="b"
      >
        <FormSignUp
          submitLabel="Get Started Today"
          labelClassName="desktop-only"
          messageLabel="Questions or Comments"
        />
      </ModalForm>

      <h2 className={cx.header}>
        <span className={isTablet ? headingTabletClassName : headingClassName}>
          {heading}
        </span>
        <span
          className={isTablet ? subheadingTabletClassName : subheadingClassName}
        >
          {subheading}
        </span>
      </h2>
      <ul className={cx.list}>
        {websiteItem?.map((item, key) => {
          return (
            <WebsiteItem
              key={key}
              agent={item?.agent}
              location={item?.location}
              description={item?.description}
              buttonText={buttonText}
              bgImg={bgImg}
              laptopImg={item?.laptopImg}
              tabletImg={item?.tabletImg}
              magnifyImg={item?.magnifyImg}
              ctaOnClick={() => modalRef.current.openModal()}
            />
          )
        })}
      </ul>
    </section>
  )
}

WebsiteItem.propTypes = {
  wrapperClassName: PropTypes.string,
  agent: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  bgImg: PropTypes.string,
  laptopImg: PropTypes.object,
  tabletImg: PropTypes.object,
  magnifyImg: PropTypes.string,
  agentClassName: PropTypes.string,
  locationClassName: PropTypes.string,
  ctaOnClick: PropTypes.func,
}

WebsiteList.propTypes = {
  wrapperClassName: PropTypes.string,
  bgImg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  buttonText: PropTypes.string,
  headingClassName: PropTypes.string,
  headingTabletClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  subheadingTabletClassName: PropTypes.string,
  item: PropTypes.array,
}

export default WebsiteList
