import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import Banner from '@components/global/banner/Banner'
import Faqs from '@components/global/accordions/Faqs'
import LeadContentForm05 from '@components/global/forms/bottom/LeadContentForm05'
import { Branding, WebsiteList } from '@components/pages/best-20-idx-websites'

import { useContactFormMetadata } from '@hooks/contact-form-metadata'
import {
  pageData as page,
  bannerData,
  brandingData,
  websiteListData,
  faqsData,
} from '@src/data/Best20IDXWebsites'
import useBest20IDXMetadata from '@hooks/best-20-idx-metadata'
import useMedia, { media } from '@hooks/useMedia'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './20BestIDXWebsites.module.scss'

const Best20IDXWebsites = () => {
  const {
    bannerBG,
    bannerMagnify,
    bannerTabletLandscape,
    bannerTabletSlant,
    brandingAccent,
    brandingTablet1,
    brandingTablet2,
    websiteListBG,
    websiteListQuianaLaptop,
    websiteListQuianaTablet,
    websiteListQuianaMagnify,
    websiteListCarollLaptop,
    websiteListCarollTablet,
    websiteListCarollMagnify,
    websiteListSharleneLaptop,
    websiteListSharleneTablet,
    websiteListSharleneMagnify,
    websiteListGregLaptop,
    websiteListGregTablet,
    websiteListGregMagnify,
    websiteListDrakeLaptop,
    websiteListDrakeTablet,
    websiteListDrakeMagnify,
    websiteListDaftarianLaptop,
    websiteListDaftarianTablet,
    websiteListDaftarianMagnify,
    websiteListJillsLaptop,
    websiteListJillsTablet,
    websiteListJillsMagnify,
    websiteListHigginsLaptop,
    websiteListHigginsTablet,
    websiteListHigginsMagnify,
    websiteListCaseyLaptop,
    websiteListCaseyTablet,
    websiteListCaseyMagnify,
    websiteListSherLaptop,
    websiteListSherTablet,
    websiteListSherMagnify,
    websiteListTomLaptop,
    websiteListTomTablet,
    websiteListTomMagnify,
    websiteListIvanLaptop,
    websiteListIvanTablet,
    websiteListIvanMagnify,
    websiteListBattleLaptop,
    websiteListBattleTablet,
    websiteListBattleMagnify,
    websiteListKaterinaLaptop,
    websiteListKaterinaTablet,
    websiteListKaterinaMagnify,
    websiteListDemakisLaptop,
    websiteListDemakisTablet,
    websiteListDemakisMagnify,
    websiteListSueLaptop,
    websiteListSueTablet,
    websiteListSueMagnify,
    websiteListArmarioLaptop,
    websiteListArmarioTablet,
    websiteListArmarioMagnify,
    websiteListBrianLaptop,
    websiteListBrianTablet,
    websiteListBrianMagnify,
    websiteListCapeLaptop,
    websiteListCapeTablet,
    websiteListCapeMagnify,
    websiteListJulieLaptop,
    websiteListJulieTablet,
    websiteListJulieMagnify,
    faqsBG,
  } = useBest20IDXMetadata()
  const { Aaliyah } = useContactFormMetadata()

  const websiteItemData = [
    {
      agent: websiteListData.websiteList?.websiteItem[0]?.agent,
      location: websiteListData.websiteList?.websiteItem[0]?.location,
      description: websiteListData.websiteList?.websiteItem[0]?.description,
      laptopImg: getImage(websiteListQuianaLaptop),
      tabletImg: getImage(websiteListQuianaTablet),
      magnifyImg: extractImage(websiteListQuianaMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[1]?.agent,
      location: websiteListData.websiteList?.websiteItem[1]?.location,
      description: websiteListData.websiteList?.websiteItem[1]?.description,
      laptopImg: getImage(websiteListCarollLaptop),
      tabletImg: getImage(websiteListCarollTablet),
      magnifyImg: extractImage(websiteListCarollMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[2]?.agent,
      location: websiteListData.websiteList?.websiteItem[2]?.location,
      description: websiteListData.websiteList?.websiteItem[2]?.description,
      laptopImg: getImage(websiteListSharleneLaptop),
      tabletImg: getImage(websiteListSharleneTablet),
      magnifyImg: extractImage(websiteListSharleneMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[3]?.agent,
      location: websiteListData.websiteList?.websiteItem[3]?.location,
      description: websiteListData.websiteList?.websiteItem[3]?.description,
      laptopImg: getImage(websiteListGregLaptop),
      tabletImg: getImage(websiteListGregTablet),
      magnifyImg: extractImage(websiteListGregMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[4]?.agent,
      location: websiteListData.websiteList?.websiteItem[4]?.location,
      description: websiteListData.websiteList?.websiteItem[4]?.description,
      laptopImg: getImage(websiteListDrakeLaptop),
      tabletImg: getImage(websiteListDrakeTablet),
      magnifyImg: extractImage(websiteListDrakeMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[5]?.agent,
      location: websiteListData.websiteList?.websiteItem[5]?.location,
      description: websiteListData.websiteList?.websiteItem[5]?.description,
      laptopImg: getImage(websiteListDaftarianLaptop),
      tabletImg: getImage(websiteListDaftarianTablet),
      magnifyImg: extractImage(websiteListDaftarianMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[6]?.agent,
      location: websiteListData.websiteList?.websiteItem[6]?.location,
      description: websiteListData.websiteList?.websiteItem[6]?.description,
      laptopImg: getImage(websiteListJillsLaptop),
      tabletImg: getImage(websiteListJillsTablet),
      magnifyImg: extractImage(websiteListJillsMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[7]?.agent,
      location: websiteListData.websiteList?.websiteItem[7]?.location,
      description: websiteListData.websiteList?.websiteItem[7]?.description,
      laptopImg: getImage(websiteListHigginsLaptop),
      tabletImg: getImage(websiteListHigginsTablet),
      magnifyImg: extractImage(websiteListHigginsMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[8]?.agent,
      location: websiteListData.websiteList?.websiteItem[8]?.location,
      description: websiteListData.websiteList?.websiteItem[8]?.description,
      laptopImg: getImage(websiteListCaseyLaptop),
      tabletImg: getImage(websiteListCaseyTablet),
      magnifyImg: extractImage(websiteListCaseyMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[9]?.agent,
      location: websiteListData.websiteList?.websiteItem[9]?.location,
      description: websiteListData.websiteList?.websiteItem[9]?.description,
      laptopImg: getImage(websiteListSherLaptop),
      tabletImg: getImage(websiteListSherTablet),
      magnifyImg: extractImage(websiteListSherMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[10]?.agent,
      location: websiteListData.websiteList?.websiteItem[10]?.location,
      description: websiteListData.websiteList?.websiteItem[10]?.description,
      laptopImg: getImage(websiteListTomLaptop),
      tabletImg: getImage(websiteListTomTablet),
      magnifyImg: extractImage(websiteListTomMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[11]?.agent,
      location: websiteListData.websiteList?.websiteItem[11]?.location,
      description: websiteListData.websiteList?.websiteItem[11]?.description,
      laptopImg: getImage(websiteListIvanLaptop),
      tabletImg: getImage(websiteListIvanTablet),
      magnifyImg: extractImage(websiteListIvanMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[12]?.agent,
      location: websiteListData.websiteList?.websiteItem[12]?.location,
      description: websiteListData.websiteList?.websiteItem[12]?.description,
      laptopImg: getImage(websiteListBattleLaptop),
      tabletImg: getImage(websiteListBattleTablet),
      magnifyImg: extractImage(websiteListBattleMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[13]?.agent,
      location: websiteListData.websiteList?.websiteItem[13]?.location,
      description: websiteListData.websiteList?.websiteItem[13]?.description,
      laptopImg: getImage(websiteListKaterinaLaptop),
      tabletImg: getImage(websiteListKaterinaTablet),
      magnifyImg: extractImage(websiteListKaterinaMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[14]?.agent,
      location: websiteListData.websiteList?.websiteItem[14]?.location,
      description: websiteListData.websiteList?.websiteItem[14]?.description,
      laptopImg: getImage(websiteListDemakisLaptop),
      tabletImg: getImage(websiteListDemakisTablet),
      magnifyImg: extractImage(websiteListDemakisMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[15]?.agent,
      location: websiteListData.websiteList?.websiteItem[15]?.location,
      description: websiteListData.websiteList?.websiteItem[15]?.description,
      laptopImg: getImage(websiteListSueLaptop),
      tabletImg: getImage(websiteListSueTablet),
      magnifyImg: extractImage(websiteListSueMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[16]?.agent,
      location: websiteListData.websiteList?.websiteItem[16]?.location,
      description: websiteListData.websiteList?.websiteItem[16]?.description,
      laptopImg: getImage(websiteListArmarioLaptop),
      tabletImg: getImage(websiteListArmarioTablet),
      magnifyImg: extractImage(websiteListArmarioMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[17]?.agent,
      location: websiteListData.websiteList?.websiteItem[17]?.location,
      description: websiteListData.websiteList?.websiteItem[17]?.description,
      laptopImg: getImage(websiteListBrianLaptop),
      tabletImg: getImage(websiteListBrianTablet),
      magnifyImg: extractImage(websiteListBrianMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[18]?.agent,
      location: websiteListData.websiteList?.websiteItem[18]?.location,
      description: websiteListData.websiteList?.websiteItem[18]?.description,
      laptopImg: getImage(websiteListCapeLaptop),
      tabletImg: getImage(websiteListCapeTablet),
      magnifyImg: extractImage(websiteListCapeMagnify),
    },
    {
      agent: websiteListData.websiteList?.websiteItem[19]?.agent,
      location: websiteListData.websiteList?.websiteItem[19]?.location,
      description: websiteListData.websiteList?.websiteItem[19]?.description,
      laptopImg: getImage(websiteListJulieLaptop),
      tabletImg: getImage(websiteListJulieTablet),
      magnifyImg: extractImage(websiteListJulieMagnify),
    },
  ]

  const isTablet = useMedia(media.tablet)

  return (
    <LayoutInnerPage
      wrapperClassName={cx.wrapper}
      buttonsHeight={page.buttonsHeight}
    >
      <Seo title={page.title} uri={page.uri} />
      <Banner
        wrapperClassName={cx.banner}
        layout={bannerData.layout.container}
        formLayout={bannerData.form.layout}
        bgImage={extractImage(bannerBG)}
        breadcrumbs={page.breadcrumbs}
        title={bannerData.heading.text}
        subtitle2={bannerData.subheading.text}
        description={bannerData.paragraph.text}
        formHeadingClassName={bannerData.form.subheadingClassName}
        buttonClassName={
          isTablet
            ? bannerData.form.buttonTabletClassName
            : bannerData.form.buttonClassName
        }
        devices={{
          layout: bannerData.layout.devices,
          magnify: extractImage(bannerMagnify),
          tabletLandscape: getImage(bannerTabletLandscape),
          tabletSlant: extractImage(bannerTabletSlant),
        }}
      />
      <Branding
        heading={brandingData.heading}
        subheading={brandingData.subheading}
        paragraph={brandingData.paragraph}
        buttonText={brandingData.buttonText}
        bgAccent={extractImage(brandingAccent)}
        deviceImg1={extractImage(brandingTablet1)}
        deviceImg2={extractImage(brandingTablet2)}
        bgAccentAlt={brandingData.bgImgAlt}
        deviceImgAttr1={brandingData.deviceImg[0]}
        deviceImgAttr2={brandingData.deviceImg[1]}
      />
      <WebsiteList
        wrapperClassName={cx.websiteList}
        bgImg={extractImage(websiteListBG)}
        heading={websiteListData.heading.text}
        subheading={websiteListData.subheading.text}
        buttonText={websiteListData.buttonText}
        item={websiteItemData}
      />
      <Faqs
        noZoomEffect
        wrapperClassName={cx.faqs}
        bgImg={extractImage(faqsBG)}
        items={faqsData}
      />
      <LeadContentForm05
        wrapperClassName={cx.lcf}
        model={Aaliyah}
        titleClassName={page.lcf.titleClassName}
      />
    </LayoutInnerPage>
  )
}

export default Best20IDXWebsites
