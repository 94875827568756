import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Branding.module.scss'

const Branding = ({
  wrapperClassName,
  heading,
  subheading,
  paragraph,
  headingClassName = 'heading-3',
  subheadingClassName = 'small-heading strong',
  paragraphClassName = 'default-body large',
  buttonText,
  bgAccent,
  deviceImg1,
  deviceImg2,
  bgAccentAlt,
  deviceImgAttr1,
  deviceImgAttr2,
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <div className={cx.container}>
        <div className={cx.content}>
          <h3>
            <span className={headingClassName}>{heading}</span>
            <span className={subheadingClassName}> {subheading}</span>
          </h3>
          {paragraph?.map((text, key) => {
            return (
              <p key={key} className={paragraphClassName}>
                {text}
              </p>
            )
          })}
          <Button large text={buttonText} />
        </div>

        <div
          className={cx.devices}
          style={{
            backgroundImage: `url(${bgAccent})`,
          }}
        >
          <canvas width={440} height={518} className={cx.placeholder}></canvas>
          <ElementImage
            src={deviceImg1}
            alt={deviceImgAttr1.alt}
            width={deviceImgAttr1.width}
            height={deviceImgAttr1.height}
          />
          <ElementImage
            src={deviceImg2}
            alt={deviceImgAttr2.alt}
            width={deviceImgAttr2.width}
            height={deviceImgAttr2.height}
          />
        </div>
        <div className={cx.bgAccent}>
          <ElementImage src={bgAccent} alt={bgAccentAlt} />
        </div>
      </div>
    </section>
  )
}

Branding.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph: PropTypes.array,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  buttonText: PropTypes.string,
  bgAccent: PropTypes.string,
  deviceImg1: PropTypes.string,
  deviceImg2: PropTypes.string,
  bgAccentAlt: PropTypes.string,
  deviceImgAttr1: PropTypes.object,
  deviceImgAttr2: PropTypes.object,
}

export default Branding
